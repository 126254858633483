/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChildrenContainerResponse } from './ChildrenContainerResponse';
import {
    ChildrenContainerResponseFromJSON,
    ChildrenContainerResponseFromJSONTyped,
    ChildrenContainerResponseToJSON,
    ChildrenContainerResponseToJSONTyped,
} from './ChildrenContainerResponse';

/**
 * 
 * @export
 * @interface UpdateViewContainerElementsRequest
 */
export interface UpdateViewContainerElementsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateViewContainerElementsRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewContainerElementsRequest
     */
    parent_container_id?: string;
    /**
     * 
     * @type {Array<ChildrenContainerResponse>}
     * @memberof UpdateViewContainerElementsRequest
     */
    children_containers?: Array<ChildrenContainerResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateViewContainerElementsRequest
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateViewContainerElementsRequest
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewContainerElementsRequest
     */
    container_direction?: UpdateViewContainerElementsRequestContainerDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewContainerElementsRequest
     */
    container_type?: UpdateViewContainerElementsRequestContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewContainerElementsRequest
     */
    name?: string;
}


/**
 * @export
 */
export const UpdateViewContainerElementsRequestContainerDirectionEnum = {
    Row: 'ROW',
    Column: 'COLUMN',
    Grid: 'GRID'
} as const;
export type UpdateViewContainerElementsRequestContainerDirectionEnum = typeof UpdateViewContainerElementsRequestContainerDirectionEnum[keyof typeof UpdateViewContainerElementsRequestContainerDirectionEnum];

/**
 * @export
 */
export const UpdateViewContainerElementsRequestContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    NavigationV2: 'NAVIGATION_V2',
    NavigationV2Retailer: 'NAVIGATION_V2_RETAILER',
    NavigationV2Collection: 'NAVIGATION_V2_COLLECTION',
    NavigationV2Categories: 'NAVIGATION_V2_CATEGORIES',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    FeaturedLinksRetailer: 'FEATURED_LINKS_RETAILER',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type UpdateViewContainerElementsRequestContainerTypeEnum = typeof UpdateViewContainerElementsRequestContainerTypeEnum[keyof typeof UpdateViewContainerElementsRequestContainerTypeEnum];


/**
 * Check if a given object implements the UpdateViewContainerElementsRequest interface.
 */
export function instanceOfUpdateViewContainerElementsRequest(value: object): value is UpdateViewContainerElementsRequest {
    return true;
}

export function UpdateViewContainerElementsRequestFromJSON(json: any): UpdateViewContainerElementsRequest {
    return UpdateViewContainerElementsRequestFromJSONTyped(json, false);
}

export function UpdateViewContainerElementsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateViewContainerElementsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'parent_container_id': json['parent_container_id'] == null ? undefined : json['parent_container_id'],
        'children_containers': json['children_containers'] == null ? undefined : ((json['children_containers'] as Array<any>).map(ChildrenContainerResponseFromJSON)),
        'active': json['active'] == null ? undefined : json['active'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'container_direction': json['container_direction'] == null ? undefined : json['container_direction'],
        'container_type': json['container_type'] == null ? undefined : json['container_type'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function UpdateViewContainerElementsRequestToJSON(json: any): UpdateViewContainerElementsRequest {
    return UpdateViewContainerElementsRequestToJSONTyped(json, false);
}

export function UpdateViewContainerElementsRequestToJSONTyped(value?: UpdateViewContainerElementsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'parent_container_id': value['parent_container_id'],
        'children_containers': value['children_containers'] == null ? undefined : ((value['children_containers'] as Array<any>).map(ChildrenContainerResponseToJSON)),
        'active': value['active'],
        'ordinal': value['ordinal'],
        'container_direction': value['container_direction'],
        'container_type': value['container_type'],
        'name': value['name'],
    };
}

