import { mapGetters } from 'vuex'

export const dashboardNavigation = {
  computed: {
    ...mapGetters({
      user: 'userState',
      draftCount: 'draftCount'
    })
  },
  data() {
    return {
      dashboardMenu: [
        {
          access: ['ADMIN', 'BRAND'],
          name: 'Orders',
          isExpanded: false,
          children: [
            {
              access: ['ADMIN', 'BRAND'],
              name: 'Orders',
              path: 'orders',
              supportedRoutes: ['orders', 'suborderDetails', 'orderDetails']
            },
            {
              access: ['ADMIN'],
              name: 'Custom Assortments',
              path: 'custom-assortments',
              supportedRoutes: ['customAssortments']
            },
            {
              access: ['ADMIN', 'RETAILER'],
              name: 'Invoices',
              path: 'invoices',
              supportedRoutes: ['invoices']
            },
            {
              access: ['ADMIN', 'BRAND'],
              name: 'Payments',
              path: 'payments',
              supportedRoutes: ['payments', 'paymentDetails']
            }
          ]
        },
        {
          access: ['ADMIN', 'BRAND', 'PRODUCT_BUILDER'],
          name: 'Products',
          isExpanded: false,
          children: [
            {
              access: ['ADMIN', 'BRAND', 'PRODUCT_BUILDER'],
              name: 'Products',
              path: 'products',
              supportedRoutes: ['dashboardProducts', 'createProduct', 'editProduct']
            },
            {
              access: ['BRAND'],
              name: 'Inventory',
              path: 'inventory',
              supportedRoutes: ['dashboardInventory'],
              disabled: false
            },
            {
              access: ['BRAND', 'PRODUCT_BUILDER', 'ADMIN'],
              name: 'Drafts',
              path: 'drafts',
              supportedRoutes: ['dashboardDrafts']
            },
            {
              access: ['ADMIN'],
              name: 'Collections',
              path: 'collections',
              supportedRoutes: ['dashboardCollections', 'editCollection', 'editProduct']
            }
          ]
        },
        {
          access: ['BRAND'],
          name: 'Profile',
          path: 'brands/my-brand-page',
          supportedRoutes: ['my-brand-page'],
          isExpanded: false
        },
        {
          access: ['BRAND'],
          name: 'Boards',
          path: 'brand-boards',
          supportedRoutes: ['dashboardBrandBoards']
        },
        {
          access: ['BRAND'],
          name: 'Messages',
          path: 'messages',
          checkApprovedState: true,
          checkUserState: true,
          supportedRoutes: ['dashboardMessages']
        },
        {
          access: ['RETAILER'],
          name: 'Orders & Invoices',
          isExpanded: false,
          children: [
            {
              access: ['RETAILER'],
              name: 'Orders',
              path: 'retailer/orders',
              supportedRoutes: ['ordersRetailer', 'suborderDetails', 'orderDetails']
            },
            {
              access: ['RETAILER'],
              name: 'Custom Assortments',
              path: 'custom-assortments',
              supportedRoutes: ['customAssortments']
            },
            {
              access: ['RETAILER'],
              name: 'Invoices',
              path: 'invoices',
              supportedRoutes: ['invoices']
            }
          ]
        },
        {
          access: ['ADMIN'],
          name: 'Directory',
          isExpanded: false,
          children: [
            {
              access: ['ADMIN'],
              name: 'Brands',
              path: 'brands',
              supportedRoutes: ['dashboardBrands', 'editBrand']
            },
            {
              access: ['ADMIN'],
              name: 'Retailers',
              path: 'retailers',
              supportedRoutes: ['dashboardRetailers', 'Retailer Details']
            }
          ]
        },
        {
          access: ['ADMIN'],
          name: 'Applications',
          isExpanded: false,
          children: [
            {
              access: ['ADMIN'],
              name: 'Brands',
              path: 'applications/brands',
              supportedRoutes: ['dashboardBrandApplications']
            },
            {
              access: ['ADMIN'],
              name: 'Retailers',
              path: 'applications/retailers',
              supportedRoutes: ['dashboardRetailerApplications']
            }
          ]
        },
        {
          access: ['ADMIN', 'BRAND'],
          name: 'Referrals',
          path: 'referrals',
          supportedRoutes: ['dashboardReferrals', 'dashboardCreateReferral']
        },
        {
          access: ['ADMIN'],
          name: 'Content Management',
          isExpanded: false,
          children: [
            {
              access: ['ADMIN'],
              name: 'Global',
              path: 'global-components',
              supportedRoutes: ['globalComponents']
            },
            {
              access: ['ADMIN'],
              name: 'Homepage',
              path: 'content-management',
              supportedRoutes: ['dashboardHomepage']
            },
            {
              access: ['ADMIN'],
              name: 'Nav Collections',
              path: 'edit-nav-collections',
              supportedRoutes: ['editNavCollectionsPage']
            },
            {
              access: ['ADMIN'],
              name: 'Trade Shows',
              path: 'edit-trade-show-directories',
              supportedRoutes: ['editTradeShowDirectories']
            }
          ]
        },
        {
          access: ['RETAILER'],
          name: 'Account Settings',
          isExpanded: false,
          children: [
            {
              access: ['RETAILER'],
              name: 'Profile',
              path: 'profile',
              supportedRoutes: ['dashboardProfile']
            },
            {
              access: ['RETAILER'],
              name: 'Shipping Addresses',
              path: 'addresses',
              supportedRoutes: ['dashboardAddresses', 'dashboardCreateAddress', 'dashboardEditAddress']
            },
            {
              access: ['RETAILER'],
              name: 'Payment Methods',
              path: 'payment',
              supportedRoutes: ['dashboardPaymentInfo']
            },
            {
              access: ['RETAILER'],
              name: 'Credit Details',
              path: 'credit',
              supportedRoutes: ['dashboardCredit']
            },
            {
              access: ['RETAILER'],
              name: 'Change Password',
              path: 'password',
              supportedRoutes: ['dashboardPassword']
            }
          ]
        },
        {
          access: ['ADMIN'],
          name: 'Promo codes',
          path: 'admin-promo-codes',
          supportedRoutes: ['dashboardAdminPromoCodes']
        },
        {
          access: ['BRAND'],
          name: 'Promo codes',
          path: 'brand-promo-codes',
          supportedRoutes: ['dashboardBrandPromoCodes']
        },
        {
          access: ['RETAILER'],
          name: 'Messages',
          path: 'messages',
          checkApprovedState: true,
          checkUserState: true,
          supportedRoutes: ['dashboardMessages']
        }
      ]
    }
  },

  methods: {
    hasMenuAccess
  }
}

function hasMenuAccess(link) {
  return this.user && link.access.indexOf(this.user.role) !== -1
}
