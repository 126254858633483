<template>
  <div class="account-popup-wrapper" :class="{ 'brand-wrapper': user?.role !== 'RETAILER' }" @mouseleave="closePopup()">
    <div class="account-popup">
      <div class="account-popup-content">
        <template v-if="user?.role === 'RETAILER' || forceRetailerView">
          <net-terms :tracking-location="'Account Popup'" @close="closePopup()" />
          <credit-available @close="closePopup()" />
          <router-link
            :to="'/dashboard/retailer/orders'"
            class="menu-item subheading-1"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Orders & Invoices
          </router-link>

          <router-link
            class="menu-item subheading-1"
            to="/dashboard/profile"
            data-test-id="header_link_brands"
            data-cy="browse-brands-link"
            @click.native="closePopup()"
          >
            Account Settings
          </router-link>

          <template>
            <router-link
              v-if="canSendMessage"
              class="menu-item subheading-1"
              data-test-id="header_link_messages"
              :to="{ name: 'dashboardMessages' }"
              @click.native="
                trackNavigationEvent('messages', 'messages')
                closePopup()
              "
            >
              <div class="flex align-center">
                <div class="mr-10">Messages</div>
                <div v-if="messageCount" class="count-indicator">
                  {{ messageCount }}
                </div>
              </div>
            </router-link>

            <div v-else class="menu-item subheading-1" @click="showMessageWarningToSuspendedOrDeclinedRetailers()">
              Messages
            </div>
          </template>

          <bulletin-button full-width @click="handleLogout()"> Log out </bulletin-button>
        </template>
        <template v-if="user?.role === 'BRAND' && !forceRetailerView">
          <router-link
            to="/dashboard/orders"
            class="menu-item subheading-1"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Orders
          </router-link>
          <router-link
            to="/dashboard/products"
            class="menu-item subheading-1"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Products
          </router-link>
          <router-link
            to="/dashboard/brand-boards"
            class="menu-item subheading-1"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Boards
          </router-link>
          <router-link
            to="/dashboard/brands/my-brand-page"
            class="menu-item subheading-1 pb-15 border-bottom-gray"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Profile
          </router-link>
          <router-link
            to="/dashboard/referrals"
            class="menu-item subheading-1"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Referrals
          </router-link>
          <router-link
            to="/dashboard/brand-promo-codes"
            class="menu-item subheading-1"
            data-test-id="orders-link"
            @click.native="closePopup()"
          >
            Promo Codes
          </router-link>
          <bulletin-button full-width @click="handleLogout()"> Log out </bulletin-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sessionHelper } from '../mixin/sessionHelper'
import { navigationHelper } from '../mixin/navigationHelper'
import { dialogHelper } from '../mixin/dialogHelper'

export default {
  mixins: [sessionHelper, navigationHelper, dialogHelper],
  props: {
    forceRetailerView: {
      type: Boolean
    },
    closePopup: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState',
      activeCategoryId: 'navigation/activeCategory',
      canSendMessage: 'canSendMessage'
    }),
    messageCount() {
      return this.$store.getters.notificationState
    }
  },
  mounted() {
    this.show = true
  },
  methods: {
    handleLogout
  }
}

function handleLogout() {
  this.logout()
  this.closePopup()
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';

.account-popup-wrapper {
  position: absolute;
  top: 50px;
  right: -90px;
  z-index: 18;
  cursor: pointer;

  &.brand-wrapper {
    top: 56px !important;
    right: 10px !important;
  }
}

.account-popup {
  display: block;
  z-index: 15;
  position: relative;
}

.account-popup-content {
  background: $color-white;
  padding: 24px;
  border: 1px solid $color-black;
  width: 320px;
}

.count-indicator {
  height: 20px;
  width: 20px;
  background: $color-tiffany-blue;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}

.divider {
  background: $color-grey-2;
  height: 1px;
  width: 100%;
  margin-bottom: 24px;
}

.menu-item {
  margin-bottom: 24px;
  font-family: NHaasGroteskDSPro-55Rg, serif;
  font-size: 16px;
  display: block;

  &:hover {
    cursor: pointer;
    color: $color-grey-4;
  }
}
</style>
