/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ViewComponentResponse } from './ViewComponentResponse';
import {
    ViewComponentResponseFromJSON,
    ViewComponentResponseFromJSONTyped,
    ViewComponentResponseToJSON,
    ViewComponentResponseToJSONTyped,
} from './ViewComponentResponse';

/**
 * 
 * @export
 * @interface ChildrenContainerElementsResponse
 */
export interface ChildrenContainerElementsResponse {
    /**
     * 
     * @type {Array<ViewComponentResponse>}
     * @memberof ChildrenContainerElementsResponse
     */
    view_components?: Array<ViewComponentResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ChildrenContainerElementsResponse
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChildrenContainerElementsResponse
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerElementsResponse
     */
    container_type?: ChildrenContainerElementsResponseContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerElementsResponse
     */
    container_direction?: ChildrenContainerElementsResponseContainerDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerElementsResponse
     */
    name?: string;
}


/**
 * @export
 */
export const ChildrenContainerElementsResponseContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    NavigationV2: 'NAVIGATION_V2',
    NavigationV2Retailer: 'NAVIGATION_V2_RETAILER',
    NavigationV2Collection: 'NAVIGATION_V2_COLLECTION',
    NavigationV2Categories: 'NAVIGATION_V2_CATEGORIES',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    FeaturedLinksRetailer: 'FEATURED_LINKS_RETAILER',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type ChildrenContainerElementsResponseContainerTypeEnum = typeof ChildrenContainerElementsResponseContainerTypeEnum[keyof typeof ChildrenContainerElementsResponseContainerTypeEnum];

/**
 * @export
 */
export const ChildrenContainerElementsResponseContainerDirectionEnum = {
    Row: 'ROW',
    Column: 'COLUMN',
    Grid: 'GRID'
} as const;
export type ChildrenContainerElementsResponseContainerDirectionEnum = typeof ChildrenContainerElementsResponseContainerDirectionEnum[keyof typeof ChildrenContainerElementsResponseContainerDirectionEnum];


/**
 * Check if a given object implements the ChildrenContainerElementsResponse interface.
 */
export function instanceOfChildrenContainerElementsResponse(value: object): value is ChildrenContainerElementsResponse {
    return true;
}

export function ChildrenContainerElementsResponseFromJSON(json: any): ChildrenContainerElementsResponse {
    return ChildrenContainerElementsResponseFromJSONTyped(json, false);
}

export function ChildrenContainerElementsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildrenContainerElementsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'view_components': json['view_components'] == null ? undefined : ((json['view_components'] as Array<any>).map(ViewComponentResponseFromJSON)),
        'active': json['active'] == null ? undefined : json['active'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'container_type': json['container_type'] == null ? undefined : json['container_type'],
        'container_direction': json['container_direction'] == null ? undefined : json['container_direction'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ChildrenContainerElementsResponseToJSON(json: any): ChildrenContainerElementsResponse {
    return ChildrenContainerElementsResponseToJSONTyped(json, false);
}

export function ChildrenContainerElementsResponseToJSONTyped(value?: ChildrenContainerElementsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'view_components': value['view_components'] == null ? undefined : ((value['view_components'] as Array<any>).map(ViewComponentResponseToJSON)),
        'active': value['active'],
        'ordinal': value['ordinal'],
        'container_type': value['container_type'],
        'container_direction': value['container_direction'],
        'name': value['name'],
    };
}

