import {
  AuthenticationControllerApi,
  BoardControllerApi,
  BrandControllerApi,
  BrandBoardControllerApi,
  CollectionControllerApi,
  ProductControllerApi,
  PromoCodeControllerApi,
  ReferralControllerApi,
  RetailerControllerApi,
  ViewControllerApi,
  ValidationControllerApi,
  Configuration,
  TradeShowControllerApi,
  CustomAssortmentControllerApi,
  PaymentControllerApi,
  InvoiceControllerApi,
  OnboardingControllerApi,
  OrderControllerApi,
  NavigationV2ControllerApi
} from './generated'
import env from '../env'

export const apiConfiguration = new Configuration({
  basePath: env.apiUrl(),
  middleware: [
    {
      async post(context) {
        if (context.response.status >= 400) throw await context.response.clone().json()

        return context.response
      },
      async pre(context) {
        const token = localStorage.getItem('ACCESS_TOKEN') || ''
        if (token) {
          context.init.headers = {
            ...context.init.headers,
            Authorization: `Bearer ${token}`
          }
          return context
        }
      }
    }
  ]
})

export const AuthenticationApi = new AuthenticationControllerApi(apiConfiguration)
export const BoardApi = new BoardControllerApi(apiConfiguration)
export const BrandApi = new BrandControllerApi(apiConfiguration)
export const BrandBoardApi = new BrandBoardControllerApi(apiConfiguration)
export const CollectionApi = new CollectionControllerApi(apiConfiguration)
export const ProductApi = new ProductControllerApi(apiConfiguration)
export const PromoCodeApi = new PromoCodeControllerApi(apiConfiguration)
export const ReferralApi = new ReferralControllerApi(apiConfiguration)
export const RetailerApi = new RetailerControllerApi(apiConfiguration)
export const ViewApi = new ViewControllerApi(apiConfiguration)
export const ValidationApi = new ValidationControllerApi(apiConfiguration)
export const TradeShowApi = new TradeShowControllerApi(apiConfiguration)
export const CustomAssortmentApi = new CustomAssortmentControllerApi(apiConfiguration)
export const PaymentApi = new PaymentControllerApi(apiConfiguration)
export const InvoiceApi = new InvoiceControllerApi(apiConfiguration)
export const OrdersApi = new OrderControllerApi(apiConfiguration)
export const OnboardingApi = new OnboardingControllerApi(apiConfiguration)
export const NavigationApi = new NavigationV2ControllerApi(apiConfiguration)
