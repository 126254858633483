/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewComponentResponse
 */
export interface ViewComponentResponse {
    /**
     * 
     * @type {string}
     * @memberof ViewComponentResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewComponentResponse
     */
    view_container_id?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ViewComponentResponse
     */
    info?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ViewComponentResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ViewComponentResponse
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof ViewComponentResponse
     */
    component_type?: ViewComponentResponseComponentTypeEnum;
}


/**
 * @export
 */
export const ViewComponentResponseComponentTypeEnum = {
    FeaturedLinksRetailer: 'FEATURED_LINKS_RETAILER',
    NavigationV2: 'NAVIGATION_V2',
    Category: 'CATEGORY',
    Collection: 'COLLECTION',
    Brand: 'BRAND',
    Hero: 'HERO',
    PromoBanner: 'PROMO_BANNER',
    About: 'ABOUT'
} as const;
export type ViewComponentResponseComponentTypeEnum = typeof ViewComponentResponseComponentTypeEnum[keyof typeof ViewComponentResponseComponentTypeEnum];


/**
 * Check if a given object implements the ViewComponentResponse interface.
 */
export function instanceOfViewComponentResponse(value: object): value is ViewComponentResponse {
    return true;
}

export function ViewComponentResponseFromJSON(json: any): ViewComponentResponse {
    return ViewComponentResponseFromJSONTyped(json, false);
}

export function ViewComponentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewComponentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'view_container_id': json['view_container_id'] == null ? undefined : json['view_container_id'],
        'info': json['info'] == null ? undefined : json['info'],
        'entity_id': json['entity_id'] == null ? undefined : json['entity_id'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'component_type': json['component_type'] == null ? undefined : json['component_type'],
    };
}

export function ViewComponentResponseToJSON(json: any): ViewComponentResponse {
    return ViewComponentResponseToJSONTyped(json, false);
}

export function ViewComponentResponseToJSONTyped(value?: ViewComponentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'view_container_id': value['view_container_id'],
        'info': value['info'],
        'entity_id': value['entity_id'],
        'ordinal': value['ordinal'],
        'component_type': value['component_type'],
    };
}

