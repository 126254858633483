/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateViewComponentRequest
 */
export interface UpdateViewComponentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentRequest
     */
    entity_id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateViewComponentRequest
     */
    ordinal: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentRequest
     */
    component_type?: UpdateViewComponentRequestComponentTypeEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateViewComponentRequest
     */
    attributes?: { [key: string]: string; };
}


/**
 * @export
 */
export const UpdateViewComponentRequestComponentTypeEnum = {
    FeaturedLinksRetailer: 'FEATURED_LINKS_RETAILER',
    NavigationV2: 'NAVIGATION_V2',
    Category: 'CATEGORY',
    Collection: 'COLLECTION',
    Brand: 'BRAND',
    Hero: 'HERO',
    PromoBanner: 'PROMO_BANNER',
    About: 'ABOUT'
} as const;
export type UpdateViewComponentRequestComponentTypeEnum = typeof UpdateViewComponentRequestComponentTypeEnum[keyof typeof UpdateViewComponentRequestComponentTypeEnum];


/**
 * Check if a given object implements the UpdateViewComponentRequest interface.
 */
export function instanceOfUpdateViewComponentRequest(value: object): value is UpdateViewComponentRequest {
    if (!('ordinal' in value) || value['ordinal'] === undefined) return false;
    return true;
}

export function UpdateViewComponentRequestFromJSON(json: any): UpdateViewComponentRequest {
    return UpdateViewComponentRequestFromJSONTyped(json, false);
}

export function UpdateViewComponentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateViewComponentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'] == null ? undefined : json['entity_id'],
        'ordinal': json['ordinal'],
        'component_type': json['component_type'] == null ? undefined : json['component_type'],
        'attributes': json['attributes'] == null ? undefined : json['attributes'],
    };
}

export function UpdateViewComponentRequestToJSON(json: any): UpdateViewComponentRequest {
    return UpdateViewComponentRequestToJSONTyped(json, false);
}

export function UpdateViewComponentRequestToJSONTyped(value?: UpdateViewComponentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'entity_id': value['entity_id'],
        'ordinal': value['ordinal'],
        'component_type': value['component_type'],
        'attributes': value['attributes'],
    };
}

