/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChildrenContainerElementsResponse } from './ChildrenContainerElementsResponse';
import {
    ChildrenContainerElementsResponseFromJSON,
    ChildrenContainerElementsResponseFromJSONTyped,
    ChildrenContainerElementsResponseToJSON,
    ChildrenContainerElementsResponseToJSONTyped,
} from './ChildrenContainerElementsResponse';

/**
 * 
 * @export
 * @interface CreateViewContainerElementsRequest
 */
export interface CreateViewContainerElementsRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerElementsRequest
     */
    parent_container_id?: string;
    /**
     * 
     * @type {Array<ChildrenContainerElementsResponse>}
     * @memberof CreateViewContainerElementsRequest
     */
    children_containers?: Array<ChildrenContainerElementsResponse>;
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerElementsRequest
     */
    container_type?: CreateViewContainerElementsRequestContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerElementsRequest
     */
    container_direction?: CreateViewContainerElementsRequestContainerDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateViewContainerElementsRequest
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerElementsRequest
     */
    name?: string;
}


/**
 * @export
 */
export const CreateViewContainerElementsRequestContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    NavigationV2: 'NAVIGATION_V2',
    NavigationV2Retailer: 'NAVIGATION_V2_RETAILER',
    NavigationV2Collection: 'NAVIGATION_V2_COLLECTION',
    NavigationV2Categories: 'NAVIGATION_V2_CATEGORIES',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    FeaturedLinksRetailer: 'FEATURED_LINKS_RETAILER',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type CreateViewContainerElementsRequestContainerTypeEnum = typeof CreateViewContainerElementsRequestContainerTypeEnum[keyof typeof CreateViewContainerElementsRequestContainerTypeEnum];

/**
 * @export
 */
export const CreateViewContainerElementsRequestContainerDirectionEnum = {
    Row: 'ROW',
    Column: 'COLUMN',
    Grid: 'GRID'
} as const;
export type CreateViewContainerElementsRequestContainerDirectionEnum = typeof CreateViewContainerElementsRequestContainerDirectionEnum[keyof typeof CreateViewContainerElementsRequestContainerDirectionEnum];


/**
 * Check if a given object implements the CreateViewContainerElementsRequest interface.
 */
export function instanceOfCreateViewContainerElementsRequest(value: object): value is CreateViewContainerElementsRequest {
    return true;
}

export function CreateViewContainerElementsRequestFromJSON(json: any): CreateViewContainerElementsRequest {
    return CreateViewContainerElementsRequestFromJSONTyped(json, false);
}

export function CreateViewContainerElementsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateViewContainerElementsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'parent_container_id': json['parent_container_id'] == null ? undefined : json['parent_container_id'],
        'children_containers': json['children_containers'] == null ? undefined : ((json['children_containers'] as Array<any>).map(ChildrenContainerElementsResponseFromJSON)),
        'container_type': json['container_type'] == null ? undefined : json['container_type'],
        'container_direction': json['container_direction'] == null ? undefined : json['container_direction'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function CreateViewContainerElementsRequestToJSON(json: any): CreateViewContainerElementsRequest {
    return CreateViewContainerElementsRequestToJSONTyped(json, false);
}

export function CreateViewContainerElementsRequestToJSONTyped(value?: CreateViewContainerElementsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'parent_container_id': value['parent_container_id'],
        'children_containers': value['children_containers'] == null ? undefined : ((value['children_containers'] as Array<any>).map(ChildrenContainerElementsResponseToJSON)),
        'container_type': value['container_type'],
        'container_direction': value['container_direction'],
        'ordinal': value['ordinal'],
        'name': value['name'],
    };
}

