<template>
  <div class="menu-section">
    <div v-if="!activeSubcategory" class="menu-section-list category-list">
      <div
        v-for="link in links"
        :key="link.id"
        class="menu-section-list-item flex justify-between align-center pointer"
        @click="handleClick(link)"
      >
        {{ link.trade_show_brand }}
        <Icon v-if="link.is_brand_attached_to_trade_show" icon-key="chevron-right" hover-type="OPACITY" />
      </div>
    </div>
    <div v-else>
      <div class="mobile-menu">
        <div class="back-container">
          <Icon icon-key="chevron-left" hover-type="OPACITY" :on-click="() => (activeSubcategory = null)" />
        </div>
        <Icon icon-key="close" hover-type="OPACITY" class="close-container" :on-click="onClose" />
        <div class="flex justify-start menu-content mt-20">
          <router-link class="brand-menu-item-link" :to="activeSubcategory.exhibitor_url" @click.native="onClose">
            <img class="brand-menu-image" :src="require('@/assets/img/exhibitor-link-img.jpeg')" />
            <span class="a2 mt-15">Exhibitor Directory</span>
          </router-link>
          <router-link class="brand-menu-item-link" :to="activeSubcategory.buyer_url" @click.native="onClose">
            <img class="brand-menu-image" :src="require('@/assets/img/buyer-link-img.jpeg')" />
            <span class="a2 mt-15">Buyer Directory</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationApi } from '@/api'

export default {
  name: 'BrandMobileMenu',
  props: ['onClose'],
  data() {
    return {
      links: [],
      activeSubcategory: null
    }
  },
  mounted() {
    this.getBrandLinks()
  },
  methods: {
    handleClick,
    getBrandLinks
  }
}

function handleClick(link) {
  if (link.is_brand_attached_to_trade_show) {
    this.activeSubcategory = link
  } else {
    this.$router.push(link.exhibitor_url)
    this.onClose()
  }
}

async function getBrandLinks() {
  try {
    this.links = await NavigationApi.getNavigationV2()
  } catch (error) {
    this.$handleError(this, error)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
.brand-menu-item-link {
  display: flex;
  flex-direction: column;
  width: calc(50% - 16px);
  cursor: pointer;
  margin: 0 10px;
}
.brand-menu-image {
  aspect-ratio: 0.9 / 1;
  border: 1px solid $color-black;
}
</style>
<style lang="scss" scoped src="./TheMainMobileMenu.scss" />
