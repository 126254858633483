import Vue from 'vue'
import App from './app.vue'
import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate'
import VueSweetalert2 from 'vue-sweetalert2'
import Toasted from 'vue-toasted'
import VueAnalytics from 'vue-analytics'
import InstantSearch from 'vue-instantsearch'
import VueMultianalytics from 'vue-multianalytics'
import Moment from 'vue-moment'
import Session from './services/session'
import routes from './router'
import SharedComponents from './sharedComponents'
import Env from './env.js'
import Utils from './services/utils.js'
import store from './store/store.js'
import VueMeta from 'vue-meta'
import VCalendar from 'v-calendar'
import PortalVue from 'portal-vue'
import 'v-calendar/lib/v-calendar.min.css'
import PusherConfig from './pusherConfig.js'
import VueStomp from './plugins/VueStomp/vue-stomp.js'
import createTalkSession from './talkSession.js'
import 'swiper/css/swiper.min.css'
import { VClosePopover, VPopover, VTooltip } from 'v-tooltip'
import trackEventDirective from './directives/trackEventDirective'
import './store/trackingStore.js'
import aa from 'search-insights'

import './assets/sass/app.scss'

import { datadogRum } from '@datadog/browser-rum'
import Logger from './logger'
import { scrollBehavior } from './scrollBehaviour'
import { track } from './services/tracking.js'
import { toastHelper } from './components/mixin/toastHelper'

export const EventBus = new Vue(),
  Loader = new Vue(),
  router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior: scrollBehavior as any /* @todo TS */
  })
/*
//     /*
//      * TODO:
//      * implement network requestSanitizer to exclude certain urls/endpoints from logging
//      * implement version tracking that aligns with bugsnag version on client
//      * implement error tracking , send same exceptions from bugsnag to logrocket as well
//      * ticket: https://codetribellc.atlassian.net/browse/BUL-2366
//      */
// });

Vue.use(VueStomp)
Vue.use(VCalendar, {
  datePickerShowDayPopover: false
})
Vue.use(VueMeta, {
  waitOnDestroyed: true
})
Vue.use(VueRouter)
Vue.use(SharedComponents)

// warning: Changing the `fieldsBagName` will change the global variable that `veeValidate` uses to store the fields, and is used by our own code.
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
Vue.use(InstantSearch)
Vue.use(Moment)
Vue.use(VueSweetalert2, {
  allowOutsideClick: false,
  showCloseButton: true,
  animation: false,
  width: 450,
  reverseButtons: true
})
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000
})
Vue.mixin(toastHelper)
Vue.use(PortalVue)
Vue.use(VueAnalytics, {
  id: Env.googleAnalytics(),
  router
})
Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: {
      token: Env.mixpanelToken()
    }
  }
})

const initializeDataDog = () => {
  const user = Session.getUserInfo()

  datadogRum.init({
    applicationId: 'f634b619-9780-495a-b006-0e279ecc2e45',
    clientToken: 'pub607722c304c21ec20ee6c47143e82fc6',
    site: 'datadoghq.com',
    env: Env.getOrigin(),
    service: 'bulletin',
    version: 'd7af07c',
    sampleRate: 100,
    trackInteractions: true,
    trackViewsManually: false,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [
      'https://api.bulletin.co',
      'https://feed.bulletin.co',
      'https://bulletin.co',
      'https://devshop.bulletin.co'
    ],
    trackSessionAcrossSubdomains: true
  })

  if (user) {
    datadogRum.setUser({
      id: user.id,
      email: user.email,
      role: user.role
    })
  }

  datadogRum.startSessionReplayRecording()
}

initializeDataDog()

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('VPopover', VPopover)

Vue.directive('trackevent', trackEventDirective)
Vue.config.productionTip = false

Vue.prototype.$session = Session
Vue.prototype.$eventBus = EventBus
Vue.prototype.$env = Env
Vue.prototype.$utils = Utils
Vue.prototype.$loader = Loader
Vue.prototype.$logger = Logger
Vue.prototype.$handleError = Utils.handleError
Vue.prototype.$trackEvent = function (payload) {
  track(this, payload)
}

function checkForSuspiciousUserAndBootThem() {
  const adminEmail = 'admin@bulletin.co'
  const user = JSON.parse(localStorage.getItem('USER_INFO') || '{}')
  if (user.email === adminEmail) {
    datadogRum.addAction('logFraud', {
      user: user
    })
    Session.destroy()
    window.location.href = '/'
  }
}

// eslint-disable-next-line complexity
router.beforeEach((to, from, next) => {
  checkForSuspiciousUserAndBootThem()
  const onboardDataStr = localStorage.getItem('ONBOARD_DATA')
  const onboardData = onboardDataStr ? JSON.parse(onboardDataStr) : {}
  const onboardRoute = '/dashboard/brand-page-setup'
  const isIncomplete = onboardData && onboardData.isOnboardIncomplete
  const ignoreSetupPageRedirection = to && to.name !== 'dashboardBrandPageSetup'
  const ignorePages =
    to &&
    to.name !== 'brand-preview-for-retailer' &&
    !(to.name === 'my-brand-page' && from.name === 'dashboardBrandPageSetup')
  if (isIncomplete && ignoreSetupPageRedirection && ignorePages) {
    window.location.href = onboardRoute
  }

  if (to.meta.keepScrollPosition === undefined || !to.meta.keepScrollPosition) {
    window.scrollTo(0, 0)
  }

  if (to.meta.isPublic) {
    if (from.name === 'buyer' && from.query.brandUrl && to.name === 'signIn' && !to.query.redirectUrl) {
      next({
        name: to.name,
        query: {
          redirectUrl: router.resolve({
            name: 'brand-bio',
            params: {
              brandUrl: from.query.brandUrl
            }
          }).href
        }
      })
    } else {
      next()
    }
  } else {
    const user = JSON.parse(localStorage.getItem('USER_INFO')!)
    let access = false

    if (user) {
      for (let i = 0; i < to.meta.roles.length; i++) {
        if (user.role === to.meta.roles[i]) {
          access = true

          break
        } else {
          access = false
        }
      }

      if (access) {
        next()
      } else {
        Session.destroy()
        window.location.href = '/sign-in'
      }
    } else {
      Session.destroy()
      window.location.href = '/sign-in'
    }
  }
  // eslint-disable-next-line no-prototype-builtins
  const [isCatPage, isProductPage] = [to.params.hasOwnProperty('category'), to.params.hasOwnProperty('productName')]
  // Setting document title for SEO
  let title = 'Bulletin - Wholesale and Retail'
  if (to.meta.title) {
    title += ` - ${to.meta.title} `
  } else if (isCatPage) {
    title += ` - ${to.params.category}`
  } else if (isProductPage) {
    title += ` - ${to.params.productName}`
  }
  document.title = title
})

router.afterEach((to) => {
  Utils.viewTracking(to.path)
  /*
   * after navigating away dismiss any open modals.
   * the `afterEach` block will not affect any navigation in case this block throws an error.
   */
  const excludedPath = ['/checkout']
  /* @todo TS
   * `This condition will always return true since this function is always defined. Did you mean to call it instead?`
   *  */
  // @ts-ignore
  if (Vue.swal.isVisible && !excludedPath.includes(to.path)) {
    Vue.swal.close()
  }
})

const algoliaInstance = Env.algolia()
if (algoliaInstance) {
  const { appId, token: apiKey } = algoliaInstance
  aa('init', { appId, apiKey })
}

new Vue({
  router,
  store,
  data() {
    return {
      // eslint-disable-next-line no-useless-escape
      emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      alphaNumericPattern: /^[a-z0-9]+$/i,
      vanityURLPattern: /^[a-z0-9-]+$/,
      showBanner: false
    }
  },
  beforeMount() {
    const user = Session.getUserInfo()
    if (user) {
      this.$utils.initMixpanelBeforeTrack(this)
    }
  },
  async mounted() {
    const user = Session.getUserInfo()
    if (user) {
      this.$store.commit('setUser', user)
      aa('setAuthenticatedUserToken', user.id)

      PusherConfig(this)
      if (user.role !== 'BRAND') {
        this.$store.dispatch('cart/getCartProductCount')
      }
    }

    if (user?.role === 'RETAILER') {
      await this.$utils.setRetailerStores(this)
      await this.$utils.getRetailerMe.call(this)
      this.$utils.balanceChangeHandler(Session, this)
      this.$utils.getRetailerMessageCapability.call(this)
    }

    if (user?.role === 'BRAND') {
      this.$utils.getRetailerMessageCapability.call(this)
    }

    if (user && user?.role !== 'ADMIN') {
      createTalkSession(this, user)
    }

    checkForSuspiciousUserAndBootThem()

    this.$utils.checkUtmParamsAndRedirect(this.$router)
    this.$store.dispatch('getNav')
  },
  render: (render) => render(App)
}).$mount('#app')
