<template>
  <nav class="brand-navigation-bar">
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center">
        <img
          class="logo show-md pointer mr-20"
          src="../../assets/img/img-logo.svg"
          data-test-id="header_logo_link"
          data-cy="bulletin-logo"
          @click="handleLogoClick"
        />
        <div class="show hide-md">
          <div
            class="menu-item router-link subheading-1 border-right-gray"
            @mouseover="$store.dispatch('navigation/setDropdownType', 'closed')"
          >
            <router-link :to="'/dashboard/orders'" class="menu-item-link active-hover" data-cy="dashboard-link">
              Dashboard
            </router-link>
          </div>
          <div v-for="link in links" :key="link.event_id" class="relative menu-item router-link subheading-1">
            <span
              v-if="!link.is_brand_attached_to_trade_show"
              @mouseover="$store.dispatch('navigation/setDropdownType', 'closed')"
              @mouseleave="$store.dispatch('navigation/setDropdownType', 'closed')"
            >
              <router-link
                class="menu-item-link active-hover"
                :data-test-id="`header_link_${link.trade_show_brand}`"
                :to="link.exhibitor_url"
                @click.native="trackNavigationEvent(link.trade_show_brand, link.trade_show_brand)"
              >
                {{ link.trade_show_brand }}
              </router-link>
            </span>
            <div v-else>
              <div
                class="menu-item-link active-hover"
                @mouseover="$store.dispatch('navigation/setDropdownType', link.trade_show_brand)"
              >
                {{ link.trade_show_brand }}
              </div>
              <div
                v-if="dropdown === link.trade_show_brand"
                class="hover-container"
                @mouseleave="$store.dispatch('navigation/setDropdownType', 'closed')"
              >
                <div class="m-20">
                  <h4>{{ link.trade_show_brand }}</h4>
                  <div class="flex width-100 my-20">
                    <router-link
                      :to="link.exhibitor_url"
                      class="flex flex-direction-column mr-20"
                      :data-cy="`${link.trade_show_brand}-exhibitor-link`"
                    >
                      <img class="brand-menu-image" :src="require('@/assets/img/exhibitor-link-img.jpeg')" />
                      <span class="a2 mt-15">Exhibitor Directory</span>
                    </router-link>
                    <router-link
                      :to="link.buyer_url"
                      class="flex flex-direction-column mr-20"
                      :data-cy="`${link.trade_show_brand}-buyer-link`"
                    >
                      <img class="brand-menu-image" :src="require('@/assets/img/buyer-link-img.jpeg')" />
                      <span class="a2 mt-15">Buyer Directory</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserControls />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { NavigationApi } from '@/api'
import { navigationHelper } from '../mixin/navigationHelper'
import UserControls from './UserControls.vue'

export default {
  name: 'BrandNavigationBar',
  components: {
    UserControls
  },
  mixins: [navigationHelper],
  data() {
    return {
      links: []
    }
  },
  computed: {
    ...mapGetters({
      dropdown: 'navigation/dropdownState'
    })
  },
  async mounted() {
    await this.getBrandLinks()
  },
  methods: {
    getBrandLinks,
    handleLogoClick() {
      this.trackNavigationEvent('logo', 'logo')
      this.$router.push('/')
      this.$store.dispatch('navigation/setDropdownType', 'closed')
      this.$store.dispatch('navigation/hideSideBar')
    }
  }
}

async function getBrandLinks() {
  try {
    this.links = await NavigationApi.getNavigationV2()
  } catch (error) {
    this.$handleError(this, error)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';

.brand-navigation-bar {
  padding: 16px 40px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $color-white;
  border-bottom: 1px solid $color-grey-3;
  z-index: 20;

  @include respond-to('medium') {
    padding: 16px;
  }
}

.menu-item {
  padding: 0 10px;
  margin-bottom: 5px;
  display: inline-block;
  border-bottom: 1px solid transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include transition(border-color, 150ms, ease-in-out);

  &-link {
    &.router-link-active {
      background: linear-gradient(0deg, $color-green 40%, transparent 50%);
    }

    &:hover {
      cursor: pointer;
      color: $color-grey-4;
    }
  }

  &.default-hover {
    &:hover {
      cursor: pointer;
      color: $color-grey-4;
    }
  }

  &:not(.router-link) {
    &:hover {
      cursor: pointer;
      border-color: $color-black !important;
    }
  }

  &.active {
    cursor: pointer;
    border-color: $color-black !important;
  }
}

.hover-container {
  position: fixed;
  left: 0px;
  right: 0;
  top: 73px;
  width: 100%;
  background: $color-white;
  border-bottom: 1px solid $color-black;
}

.brand-menu-image {
  width: 207px;
  height: 233px;
  border: 1px solid $color-black;
}
</style>
