<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="menu-section mt-20">
    <h3 class="section-heading">Dashboard</h3>

    <div class="menu-section-list">
      <template v-for="(menuItem, index) in menuItems" v-if="hasMenuAccess(menuItem)">
        <template v-if="!menuItem.children">
          <router-link
            v-if="!menuItem.disabled"
            :key="`menu_item_${index}`"
            class="menu-section-list-item flex justify-between align-center"
            :to="getMenuItemLink(menuItem)"
            @click.native="closeMenu()"
          >
            <template v-if="!menuItem.counter">
              {{ menuItem.name }}
            </template>
            <div v-else class="flex align-center">
              <div class="mr-10">
                {{ menuItem.name }}
              </div>
              <div class="count-indicator">
                {{ menuItem.counter }}
              </div>
            </div>
          </router-link>

          <div
            v-else
            :key="`menu_item_${index}`"
            class="menu-section-list-item flex justify-between align-center"
            @click="
              menuItem.clickOnDisabledState()
              closeMenu()
            "
          >
            {{ menuItem.name }}
          </div>
        </template>

        <template v-else>
          <div
            :key="`menu_section_${index}`"
            class="menu-section-list-item flex justify-between align-center"
            @click="toggleSubSectionVisibility(menuItem.name)"
          >
            {{ menuItem.name }}
            <Icon :icon-key="isSubSectionVisible(menuItem.name) ? 'chevron-up' : 'chevron-down'" hover-type="OPACITY" />
          </div>
          <div v-if="isSubSectionVisible(menuItem.name)" class="subsection-container">
            <router-link
              v-for="(subMenuItem, subMenuItem_index) in menuItem.children"
              v-if="hasMenuAccess(subMenuItem)"
              :key="subMenuItem_index"
              class="menu-section-list-item flex justify-between align-center"
              :to="getMenuItemLink(subMenuItem)"
              @click.native="closeMenu()"
            >
              <template v-if="!subMenuItem.counter">
                {{ subMenuItem.name }}
              </template>
              <div v-else class="flex align-center">
                <div class="mr-10">
                  {{ subMenuItem.name }}
                </div>
                <div class="count-indicator">
                  {{ subMenuItem.counter }}
                </div>
              </div>
            </router-link>
          </div>
        </template>

        <div v-if="index !== menuItems.length" :key="index" class="divider" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { dialogHelper } from '@/components/mixin/dialogHelper'
import { dashboardNavigation } from '@/components/mixin/dashboardNavigation'

export default {
  mixins: [dialogHelper, dashboardNavigation],
  data: function () {
    return {
      expandedSections: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState',
      canSendMessage: 'canSendMessage'
    }),
    messageCount() {
      return this.$store.getters.notificationState
    },
    menuItems() {
      const menuForRetailer = [
        {
          name: 'Orders & Invoices',
          children: [
            { name: 'Orders', path: 'retailer/orders', access: ['RETAILER'] },
            { name: 'Custom Assortments', path: 'custom-assortments', access: ['RETAILER'] },
            { name: 'Invoices', path: 'invoices', access: ['RETAILER'] }
          ],
          access: ['RETAILER']
        },
        {
          name: 'Account Settings',
          children: [
            { name: 'Profile', path: 'profile', access: ['RETAILER'] },
            { name: 'Shipping Addresses', path: 'addresses', access: ['RETAILER'] },
            { name: 'Payment Methods', path: 'payment', access: ['RETAILER'] },
            { name: 'Credit Details', path: 'credit', access: ['RETAILER'] },
            { name: 'Change Password', path: 'password', access: ['RETAILER'] }
          ],
          access: ['RETAILER']
        },
        {
          name: 'Messages',
          path: 'messages',
          counter: this.messageCount,
          disabled: !this.canSendMessage,
          clickOnDisabledState: this.showMessageWarningToSuspendedOrDeclinedRetailers,
          access: ['RETAILER']
        }
      ]

      let menu = this.user.role === 'RETAILER' ? menuForRetailer : this.dashboardMenu
      this.setDraftCountOnForReviewMenu(menu)

      return menu
    }
  },
  methods: {
    closeMenu,
    isSubSectionVisible,
    toggleSubSectionVisibility,
    getMenuItemLink,
    setDraftCountOnForReviewMenu
  }
}

function toggleSubSectionVisibility(menuKey) {
  if (this.isSubSectionVisible(menuKey)) {
    this.expandedSections = this.expandedSections?.filter((key) => key !== menuKey)
  } else {
    this.expandedSections.push(menuKey)
  }
}

function isSubSectionVisible(name) {
  return this.expandedSections.includes(name)
}

function closeMenu() {
  this.$store.dispatch('navigation/hideSideBar')
}

function getMenuItemLink(menu) {
  return menu.notDashboardLink ? menu.path : `/dashboard/${menu.path}`
}
function setDraftCountOnForReviewMenu(menu) {
  const forReviewParentIndex = menu?.findIndex((item) => item?.name === 'Products')
  const forReviewIndex = menu[forReviewParentIndex]?.children?.findIndex((item) => item?.path === 'drafts')

  if (forReviewParentIndex && forReviewIndex) {
    menu[forReviewParentIndex].children[forReviewIndex].counter = this.draftCount
  }
  return menu
}
</script>
<style lang="scss" scoped src="./TheMainMobileMenu.scss"></style>
<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';

.subsection-container {
  padding-left: 24px;
}
</style>
